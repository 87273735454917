import React, {useEffect, useMemo, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {Link, useNavigate} from 'react-router-dom';
import {Card, Typography, List} from 'antd';
import HelmetWrapper from '../../layouts/HelmetWrapper';

const {Link: AntLink} = Typography;

function TCaseList() {

    // const random_seed = useMemo(() => Math.floor(Math.random() * 10) * 100, []);
    const random_seed = 0;
    const time_setup = 20;
    const [time, setTime] = useState(time_setup);
    const navigate = useNavigate();

    const items = useMemo(() => {
        return Array.from({length: 100}, (_, index) => index + random_seed);
    }, [random_seed]);

    const info = `
* 定位到35的位置
* 点击35，转到35 tcase detail 页
* goback回来，等${time_setup}秒
* ${time_setup}秒后页面会redirect到tcase verify页
* 正确处理后，再回到tcase index
  `;


    useEffect(() => {
        if (time > 0) {
            const timer = setTimeout(() => setTime(time - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            // 如果 query中传递了 no_nav，则不跳转
            let search = new URLSearchParams(window.location.search);
            if (search.has('no_nav')) {
                return;
            } else {
                navigate('/tcase/verify');
            }
        }
    }, [time, navigate]);

    const handleLinkClick = (e, item) => {
        e.preventDefault();
        navigate(`/tcase/detail?id=${item}`, {state: {tag: item}});
    };

    return (
        // @ts-ignore
        <HelmetWrapper>

            <Card title="任务">
                <p>倒计时: {time} 去往 verify</p>
                <ReactMarkdown>{info}</ReactMarkdown>
            </Card>

            <List
                dataSource={items}
                renderItem={item => (
                    <List.Item>
                        <AntLink>
                            <a href={`/tcase/detail?id=${item}`} my-tag={item}
                               onClick={(e) => handleLinkClick(e, item)}>
                                详情页 {item}
                            </a>
                        </AntLink>
                    </List.Item>
                )}
            />


        </HelmetWrapper>
    );
}

export default TCaseList;
