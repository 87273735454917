import React, {useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import {Card} from 'antd';
import HelmetWrapper from "@src/layouts/HelmetWrapper.tsx";

function TCaseDetail() {


    return (
        // @ts-ignore
        <HelmetWrapper>

            {/*获取url参数id*/}
            <Card title="任务">
                <ReactMarkdown>{`
详情页参数内容： ${new URLSearchParams(location.search).get('id')}
                `}</ReactMarkdown>
            </Card>

        </HelmetWrapper>
    );
}

export default TCaseDetail;
