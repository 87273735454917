import React, {useEffect, useMemo, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import {Link, useNavigate} from 'react-router-dom';
import {Card, Typography, List, Button, Flex} from 'antd';
import HelmetWrapper from '../../layouts/HelmetWrapper';

const {Link: AntLink} = Typography;


function VexGoColorPad() {
    const [color, setColor] = useState('');

    useEffect(() => {
        // 更新 body 背景颜色
        document.body.style.backgroundColor = color;
    }, [color]);

    return (
        // @ts-ignore
        <Flex gap="small" wrap="wrap">
            <Button onClick={() => setColor('red')}> 红 色 </Button>
            <Button onClick={() => setColor('green')}> 绿 色 </Button>
            <Button onClick={() => setColor('blue')}> 蓝 色 </Button>
        </Flex>
    );
}


export default VexGoColorPad;
