import React, {useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import {Card} from 'antd';
import HelmetWrapper from "@src/layouts/HelmetWrapper.tsx";

function TCaseVerify() {

    return (
        // @ts-ignore
        <HelmetWrapper>

            <Card title="任务">
                <ReactMarkdown>{`验证页面！！！！`}</ReactMarkdown>
            </Card>

        </HelmetWrapper>
    );
}

export default TCaseVerify;
