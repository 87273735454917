import React, {useRef, useState} from 'react';
import {Button, Col, Flex, Input, Result, Row, Typography} from 'antd';
import {LuckyWheel, LuckyGrid} from '@lucky-canvas/react';

const {Title, Paragraph, Text, Link} = Typography;

var iN = function () {
    var hR = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var hK = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return [hR, hK];
};

var iM = function () {
    var iZ = [screen.width, screen.height];
    var iW = [screen.availWidth, screen.availHeight];
    var iX = screen.colorDepth;
    var iY = screen.pixelDepth;
    return [iZ, iW, iX, iY];
};

function Home() {


    return (
        <Typography>
            <Title>NexteraFlow</Title>

            <Paragraph>
                主要放一些源码分析和学习笔记，欢迎大家一起学习。
            </Paragraph>

            <Paragraph>
                屏幕信息1：{JSON.stringify(iN())}<br/>
                屏幕信息2：{JSON.stringify(iM())}<br/>
            </Paragraph>

            <a href="https://beian.miit.gov.cn">京ICP备2024075652号</a>
        </Typography>
    )
}

export default Home;
