import React from 'react'
import ReactDOM from 'react-dom/client'
import Home from '@view/Home'
import LuckyBox from '@view/LuckyBox'
import {TCaseIndex, TCaseDetail, TCaseVerify} from '@view/TCase'
import {VexGoColorPad} from "@view/VexGo";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/luckybox",
        element: <LuckyBox/>,
    },
    {
        path: "/tcase",
        element: <TCaseIndex/>
    },
    {
        path: "/tcase/detail",
        element: <TCaseDetail/>
    },
    {
        path: "/tcase/verify",
        element: <TCaseVerify/>
    },
    {
        path: "/vexgo",
        element: <VexGoColorPad/>
    },
    {
        path: "/vexgo/colorpad",
        element: <VexGoColorPad/>
    },
]);

const rootElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
)
