import React from 'react';
import {Helmet} from 'react-helmet';

// @ts-ignore
const HelmetWrapper: React.FC = ({children}) => {
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <meta
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
                    name="viewport"/>
                <script src="/static/js/tcase_base.js" type="text/javascript"></script>
                <title>Nextraflow</title>
            </Helmet>
            {children}
        </>
    );
};

export default HelmetWrapper;
